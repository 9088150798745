var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{attrs:{"tags":_vm.tariffTags},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$t('reputation_extended_page_description'))+" "),_c('span',{staticClass:"underline hover:cursor-pointer",on:{"click":function($event){_vm.pageHelpMessageIsOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('more'))+" ")])])]},proxy:true}])}),_c('a-card',[(!(_vm.$store.state.chatState.chat.config.use_karma || _vm.$store.state.chatState.chat.config.use_anti_karma))?_c('div',[_c('a-alert',{attrs:{"show-icon":""}},[_c('template',{slot:"description"},[_vm._v(" "+_vm._s(((_vm.$t('reputation_disable_use_karma_alert')) + " "))+" "),_c('span',{staticClass:"hover:cursor-pointer underline",on:{"click":function($event){
var _obj;
return _vm.$router.push({name: 'reputation', params: ( _obj = {}, _obj[_vm.EntityTypes.CHAT_ID] = _vm.$store.state.chatState.chat.chat_id, _obj )})}}},[_vm._v(" "+_vm._s(_vm.$t('reputation_disable_use_karma_main_reputation_settings'))+" ")])])],2)],1):_vm._e(),(!_vm.getTagsByFieldKey("extended_reputation"))?[_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'reputation_mode',
            'options': _vm.reputation_mode_options,
            'disabled': !(_vm.$store.state.chatState.chat.config.use_karma || _vm.$store.state.chatState.chat.config.use_anti_karma),
            'hasAnchor': true,
          }
        }}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$store.state.chatState.chat.config.reputation_mode === 'Separated')?_c('div',[(_vm.$store.state.chatState.chat.config.use_karma || _vm.$store.state.chatState.chat.config.use_anti_karma)?_c('nested-content',[(_vm.$store.state.chatState.chat.config.use_karma)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.stateChatConfigPath,
                  'key': 'admin_karma_message',
                  'targetToUpload': function () { return _vm.groupUploadTarget; },
                  'editorMode': _vm.MediaEditorMode.TelegramShort,
                  'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
                  'availableButtonsTypes': _vm.defaultMessageEditorButtons,
                  'base-api-url': 'api.chatkeeper.app/cabinet',
                  'hasAnchor': true,
                },
              }}}):_vm._e(),(_vm.$store.state.chatState.chat.config.use_anti_karma)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.stateChatConfigPath,
                  'key': 'admin_anti_karma_message',
                  'targetToUpload': function () { return _vm.groupUploadTarget; },
                  'editorMode': _vm.MediaEditorMode.TelegramShort,
                  'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
                  'availableButtonsTypes': _vm.defaultMessageEditorButtons,
                  'base-api-url': 'api.chatkeeper.app/cabinet',
                  'hasAnchor': true,
                },
              }}}):_vm._e()],1):_vm._e()],1):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$store.state.chatState.chat.config.reputation_mode === 'Separated' ||
            _vm.$store.state.chatState.chat.config.reputation_mode === 'Admins')?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'allow_reputation_only_from_whitelist',
                'disabled': !(_vm.$store.state.chatState.chat.config.use_karma || _vm.$store.state.chatState.chat.config.use_anti_karma),
                'hasAnchor': true,
              },
            }}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$store.state.chatState.chat.config.allow_reputation_only_from_whitelist)?_c('nested-content',[_c('highlight-anchor',{staticClass:"my-3 py-1",attrs:{"has-anchor-icon":"","anchor-key":"reputationUsersWhitelist"}},[_c('config-field',{staticClass:"mx-1",attrs:{"title":_vm.$t('field_reputation_users_whitelist_title'),"mini-help-message":_vm.$t('field_reputation_users_whitelist_mini_help_message')}},[_c('add-admin',{attrs:{"add-type":_vm.AddAdminType.Reputation,"users-path":{
                      model: _vm.stateChatPath,
                      key: 'reputationUsersWhitelist'
                    }}})],1)],1)],1):_vm._e()],1)],1):_vm._e()],1),_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'reputation_calculation_mode',
            'options': _vm.reputation_calculation_mode_options,
            'disabled': !(_vm.$store.state.chatState.chat.config.use_karma || _vm.$store.state.chatState.chat.config.use_anti_karma),
            'hasAnchor': true,
          }
        }}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$store.state.chatState.chat.config.reputation_calculation_mode === 'Random')?_c('nested-content',[_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'random_reputation_min',
                'hasAnchor': true,
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'random_reputation_max',
                'min': _vm.$store.state.chatState.chat.config.random_reputation_min || 0,
                'hasAnchor': true,
              }
            }}})],1):_vm._e()],1),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'reputation_with_mention',
            'disabled': !(_vm.$store.state.chatState.chat.config.use_karma || _vm.$store.state.chatState.chat.config.use_anti_karma),
            'hasAnchor': true,
          }
        }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'reputation_overtake_message',
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'placeholders': _vm.SPEC_REP_TRIG_OVR_PLACEHOLDERS,
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'hasAnchor': true,
          },
        }}})]:_c('div',{staticClass:"relative w-full"},[_c('not-available-options-overlay',{staticClass:"relative",attrs:{"video":_vm.$i18n.locale !== 'ru' ?
          'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_Advanced reputation settings_en.m4v' :
          'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_Advanced reputation settings_ru.m4v.m4v',"description":_vm.$t("option_description_extended_reputation"),"tags":_vm.tariffTags}})],1)],2),_c('a-modal',{staticClass:"reputation-extended-description-modal",attrs:{"title":_vm.$t('hint'),"footer":null},model:{value:(_vm.pageHelpMessageIsOpen),callback:function ($$v) {_vm.pageHelpMessageIsOpen=$$v},expression:"pageHelpMessageIsOpen"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('reputation_extended_page_help_message'))}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }