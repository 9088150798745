













































































































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { Tag } from "../../../includes/types";
import Placeholders from "@/mixins/placeholders/placeholders";
import EditorButtonsHelpView from "@/includes/helpViews/editorButtonsHelpView.vue";
import { InputSetups } from "../../../mixins/input-setups"
import TariffsTagsHelper from "../../../mixins/TariffsTagsHelper";
import { AddAdminType } from '@/includes/types/AddAdminBy/Enums';
import AddAdmin from '@/components/AddAdmin/AddAdmin.vue';

import { UseFields } from "piramis-base-components/src/components/Pi"
import { SelectOption } from "piramis-base-components/src/logic/types";
import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types';
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types';
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue';
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue';

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    NotAvailableOptionsOverlay,
    CenteredColumnLayout,
    PageTitle,
    HighlightAnchor,
    ConfigField,
    AddAdmin
  },
  data() {
    return {
      MediaEditorMode,
      EditorButtonsHelpView,
      EntityTypes,
      AddAdminType
    }
  }
})
export default class ReputationExtended extends Mixins<UseFields, InputSetups, TariffsTagsHelper, Placeholders>(UseFields, InputSetups, TariffsTagsHelper, Placeholders) {
  pageHelpMessageIsOpen = false

  get tariffTags(): Array<Tag> | null {
    return this.getTagsByFieldKey('extended_reputation')
  }

  reputation_calculation_mode_options(): Promise<Array<SelectOption>> {
    return Promise.resolve([
      {
        label: this.$t(`reputation_standart`),
        value: "Standart",
      }, {
        label: this.$t(`reputation_complex`),
        value: "Complex",
      }, {
        label: this.$t(`reputation_random`),
        value: "Random",
      },
    ])
  }

  reputation_mode_options(): Promise<Array<SelectOption>>  {
    return Promise.resolve([
      {
        label: this.$t(`reputation_all`),
        value: "All",
      }, {
        label: this.$t(`reputation_admins`),
        value: "Admins",
      }, {
        label: this.$t(`reputation_separated`),
        value: "Separated",
      },
    ])
  }
}
